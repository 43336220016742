import React from "react"
import Payment from "../../../../components/payment";
import PrivateRoute from "../../../../components/privateRoute";

class PaymentPage extends React.Component {
    render() {
        return (
            <PrivateRoute path="/account/order/payment" component={Payment} location={this.props.location} showSingleFooter={true} environment={this.props.data.site.siteMetadata.activeEnv} seoFields={seoFields}/>
        )
    }
}

const seoFields = {
  description: "",
  // lang,
  // meta,
  // keywords,
  title: "Payment",
}

export const query = graphql`
query {
    site {
      siteMetadata {
        activeEnv
      }
    }
  }
`

export default PaymentPage