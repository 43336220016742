import React from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { createPending, processPayUMoney, editPending, processRazorpaySuccess } from "../../services/orderProcessing";
import Helmet from "react-helmet"
import PaymentStructure from "../../shared/paymentStructure";
import styles from "./styles.module.css";
import { Collapse, Button, Card } from 'reactstrap';
import { getToken } from "../../services/auth";
import { getAccountPrefix } from "../../common/gFunctions";
import PlanInformation from "../../shared/planInformation";
import { Payment_Mode } from "../../common/constants";
import ProceedButton from "../proceedButton";
import DefaultLoader from "../../shared/loader";

class PaymentComponent extends React.Component {
    constructor(props) {

        super(props);

        const token = getToken();

        this.state = {
            collapse: false,
            PaymentDetails: {
                PaymentModeCode: Payment_Mode.ONLINE,
                CustBankName: '',
                PaymentRefNumCheque: '',
                PaymentRefNumNEFT: '',
            },
            proceedOnlineLoading: false,
            proceedOfflineLoading: false,
            proceedToStatusLoading: false,
            token,
            isRazorPay: true
        };

        this.props.setOrderPending(null);

        this.chequeField = React.createRef();
        this.neftField = React.createRef();
    }


    componentWillUnmount() {
        document.body.classList.remove('sel-background'); 
    }

    componentDidMount() {
        document.body.classList.add('sel-background'); 
        if (!this.props.orderConfirm) {
            navigate(getAccountPrefix(this.state.token)+'/order/product');
        } else if (this.props.orderPending || this.props.orderEvaluated) {
            if (this.state.token) {
                navigate('/account/plan');
            } else {
                navigate('/');
            }
        }
    }

    setPaymentMode = (PaymentModeCode) => {
        this.setState({
            PaymentDetails: {
                ...this.state.PaymentDetails,
                PaymentModeCode
            },
            collapse: PaymentModeCode===this.state.PaymentDetails.PaymentModeCode ? !this.state.collapse : false
        }, () => {
            if (!this.state.collapse) {
                setTimeout(() => {
                    if (PaymentModeCode === Payment_Mode.CHEQUE) {
                        this.focusChequeField();
                    } else if (PaymentModeCode === Payment_Mode.NEFT) {
                        this.focusNeftField();
                    }
                },100)
            }
        });

    }

    focusChequeField = () => {
        if (this.chequeField && this.chequeField.current) {
            this.chequeField.current.focus();
        }
    }

    focusNeftField = () => {
        if (this.neftField && this.neftField.current) {
            this.neftField.current.focus();
        }
    }

    onlinePayment = () => {
        this.shouldLogout();
        this.setState({proceedOnlineLoading: true})
        const token = this.props.orderProcessingTokenData && this.props.orderProcessingTokenData.access_token;
        if (this.props.orderPending) {
            this.launchPaymentGateway(this.props.orderPending,token);  
        } else {
            const tempConfirmationKey = this.props.orderConfirm && this.props.orderConfirm.TempConfirmationKey;
            var paymentDetails = {
                PaymentModeCode: this.state.isRazorPay ? Payment_Mode.RAZORPAY_ONLINE : Payment_Mode.ONLINE,
            }
            this.getPendingApi(token,tempConfirmationKey,paymentDetails).then(({error,response}) => {
                if (error.status>=400) {
                    console.log('ERROR',error);
                } else {
                    this.props.setOrderPending(response);
                    this.launchPaymentGateway(response,token);    
                }
            })
        }
    }

    shouldLogout = () => {
        if (this.state.token !== getToken()) {
            navigate('/login');
        }
    }

    getPendingApi = (token, tempConfirmationKey, paymentDetails=null) => {
        const orderRefNum = this.props.orderPending && this.props.orderPending.OrderRefNum;
        if (orderRefNum) {
            return editPending(token, orderRefNum, paymentDetails)
        } else {
            return createPending(token, tempConfirmationKey, paymentDetails)
        }
    }

    offlinePayment = () => {
        this.shouldLogout();
        this.setState({proceedToStatusLoading: true})
        const token = this.props.orderProcessingTokenData && this.props.orderProcessingTokenData.access_token;
        const tempConfirmationKey = this.props.orderConfirm && this.props.orderConfirm.TempConfirmationKey;

        var paymentDetailsState = this.state.PaymentDetails;
        var paymentDetails = {
            PaymentModeCode: paymentDetailsState.PaymentModeCode,
            CustBankName: paymentDetailsState.CustBankName,
            PaymentRefNum: paymentDetailsState.PaymentModeCode===Payment_Mode.CHEQUE ? paymentDetailsState.PaymentRefNumCheque : paymentDetailsState.PaymentRefNumNEFT
        }
        this.getPendingApi(token,tempConfirmationKey,paymentDetails).then(({error,response}) => {
            if (error.status>=400) {
                console.log('ERROR',error);
            } else {
                this.props.setOrderPending(response);
                navigate(getAccountPrefix(this.state.token)+'/order/payment/status');
            }
        })
    }

    onChange = (e, type, isNumber=false) => {
        const value = isNumber ? +e.target.value : (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
        if (type) {
            const section = this.state[type];
            section[e.target.name] = value;
            this.setState({[section]: section});
        } else {
            this.setState({[e.target.name]: value});
        }
    }

    onEditClick = () => {
        navigate(getAccountPrefix(this.state.token)+'/order/details');
    }

    render() {
        console.log(this.props.environment,'activeEnv');
        if (this.props.orderConfirm) {
            const priceDetails = this.props.orderConfirm.PriceDetails;
            return (
                <>
                    <Helmet>
                        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
                        {
                            this.props.environment !== 'production' ?
                            <script id="bolt" src="https://sboxcheckout-static.citruspay.com/bolt/run/bolt.min.js" bolt-
                                color="<color-code>" bolt-logo="<image path>"></script>
                                :
                            <script id="bolt" src="https://checkout-static.citruspay.com/bolt/run/bolt.min.js" bolt-color="<color-
                            code>" bolt-logo="<image path>"></script>
                        }
                        <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
                    </Helmet>
                    <section className={styles.paymentFTU}>
                        <DefaultLoader isLoad={this.state.proceedToStatusLoading} isFull={true}/>
                        <div className={["container", styles.pay_container].join(" ")}>
                            <div className="row">
                                <PaymentStructure progress={95}/>
                            </div>
                            <div className={["tab-content", styles.payContent, "text-center", "w-100"].join(" ")}>
                                <div className={["tab-pane", "container", "shipping_information", "active"].join(" ")} id="shipping_information">
                                    <h2>Select payment option</h2>
                                    {   
                                        this.props.orderPlacementDetails && (priceDetails.OrderType === 2 || priceDetails.OrderType === 3) &&
                                        <PlanInformation existingPlan={this.props.orderPlacementDetails.Plan}/>
                                    }
                                    <PlanInformation priceDetails={priceDetails}/>
                                    <div className={[styles.form_container, "text-left", "shadow-sm", "bg-white", "rounded", "p-md-5", "p-4"].join(" ")}>
                                        <div className={["accordion", "mpr_accordion"].join(" ")} id="accordionExample">
                                        <div className={["card",styles.horizontalLine].join(" ")}>
                                            <div id="headingOne">
                                                <Button className={[ styles.btnLink, "w-100", "pl-0", "pr-0", styles.btnSecondary, this.state.PaymentDetails.PaymentModeCode === Payment_Mode.ONLINE && !this.state.collapse ? styles.open : ""].join(" ")} type="button" onClick={()=>this.setPaymentMode(Payment_Mode.ONLINE)} >Pay Online <i className="fa fa-angle-down float-right" aria-hidden="true"></i></Button>
                                            </div>
                                            <Collapse isOpen={this.state.PaymentDetails.PaymentModeCode === Payment_Mode.ONLINE && !this.state.collapse} >
                                                <Card>
                                                    <div className={[styles.buttonContainer].join(" ")}>
                                                        <ProceedButton disabled={false} onClick={this.onlinePayment} loading={this.state.proceedOnlineLoading} btnClasses={["btn", "btn-primary", "rounded", "col-md-8"].join(" ")} buttonText={'Proceed to Payment'}/>
                                                    </div>
                                                </Card>
                                            </Collapse>            
                                        </div>
                                        <div className={["card",styles.horizontalLine].join(" ")}>
                                            <div id="headingTwo">
                                                <Button className={[ styles.btnLink, "w-100", "pl-0", "pr-0", styles.btnSecondary, this.state.PaymentDetails.PaymentModeCode === Payment_Mode.CHEQUE && !this.state.collapse ? styles.open : ""].join(" ")} type="button" onClick={() => this.setPaymentMode(Payment_Mode.CHEQUE)} > Pay by Cheque  <i className="fa fa-angle-down float-right" aria-hidden="true"></i></Button>
                                            </div>
                                            <Collapse isOpen={this.state.PaymentDetails.PaymentModeCode === Payment_Mode.CHEQUE && !this.state.collapse} >
                                                <Card>
                                                    <div className={["mt-3", "mb-3"].join(" ")}>
                                                        <div className="form-row">
                                                            <div className={["form-group", "col-12"].join(" ")}>
                                                                <input ref={this.chequeField} type="text" className={["form-control",styles.offlineField].join(" ")} placeholder={'Cheque No.'} name={'PaymentRefNumCheque'} value={this.state.PaymentDetails.PaymentRefNumCheque} onChange={(e) => this.onChange(e,'PaymentDetails')}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className={["form-group", "col-12"].join(" ")}>
                                                                <input type="text" className={["form-control",styles.offlineField].join(" ")} placeholder="Bank Name" name={'CustBankName'} value={this.state.PaymentDetails.CustBankName} onChange={(e) => this.onChange(e,'PaymentDetails')}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className={["mt-2 mb-2", "col-md-12"].join(" ")}>
                                                                <ProceedButton disabled={false} onClick={this.offlinePayment} loading={this.state.proceedOfflineLoading} btnClasses={["btn", "btn-primary", "rounded", "pl-4", "pr-4", "col-md-8"].join(" ")} buttonText={'Confirm Purchase'}/>
                                                            </div>
                                                        </div>
                                                        <div className={[styles.mprofitPaymentDetails].join(" ")}>
                                                            Please issue your cheque in favour of <span>"MPROFIT SOFTWARE PRIVATE LIMITED"</span>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Collapse>         
                                        </div>
                                        <div className={["card",styles.horizontalLine].join(" ")}>
                                            <div id="headingTwo">
                                                <Button className={[ styles.btnLink, "w-100", "pl-0", "pr-0", styles.btnSecondary, this.state.PaymentDetails.PaymentModeCode === Payment_Mode.NEFT && !this.state.collapse ? styles.open : ""].join(" ")} type="button" onClick={() => this.setPaymentMode(Payment_Mode.NEFT)} > Pay by NEFT  <i className="fa fa-angle-down float-right" aria-hidden="true"></i></Button>
                                            </div>
                                            <Collapse isOpen={this.state.PaymentDetails.PaymentModeCode === Payment_Mode.NEFT && !this.state.collapse} >
                                                <Card>
                                                    <div className={["mt-3", "mb-3"].join(" ")}>
                                                        <div className="form-row">
                                                            <div className={["form-group", "col-md-12"].join(" ")}>
                                                                <input ref={this.neftField} type="text" className={["form-control",styles.offlineField].join(" ")} placeholder={'UTR / Reference No.'} name={'PaymentRefNumNEFT'} value={this.state.PaymentDetails.PaymentRefNumNEFT} onChange={(e) => this.onChange(e,'PaymentDetails')}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className={["mt-2 mb-2", "col-md-12"].join(" ")}>
                                                                <ProceedButton disabled={false} onClick={this.offlinePayment} loading={this.state.proceedOfflineLoading} btnClasses={["btn", "btn-primary", "rounded", "pl-4", "pr-4", "col-md-8"].join(" ")} buttonText={'Confirm Purchase'}/>
                                                            </div>
                                                        </div>
                                                        <div className={["d-flex flex-column",styles.mprofitPaymentDetails].join(" ")}>
                                                            <span>MProfit NEFT Details</span>
                                                            <span>Bank Name: State Bank of India</span>
                                                            <span>Account Type: Current</span>
                                                            <span>Account Number: 30574591121</span>
                                                            <span>IFSC: SBIN0008586</span>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Collapse>         
                                        </div>
                                        </div>
                                        <div className="row">
                                            <div className={["form-group", "col-md-12", "mt-4", "mb-0"].join(" ")}>
                                                <button href="#" className={["btn", "light_btn", "btn-block"].join(" ")}  onClick={this.onEditClick}> <i className={["fa", "fa-angle-left", "mr-1"].join(" ")} aria-hidden="true"></i>Back </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )
        } else {
            return <></>
        }
    }

    launchPaymentGateway = (order, token) => {
        if (this.state.isRazorPay) {
            this.launchRazorPay(order, token);
        } else {
            this.launchPayU(order, token);
        }
    }

    launchPayU = (order, token) => {
        var data = {
            key: 'HAkzR0',
            txnid: order.OrderRefNum,
            hash: order.PayUMoneyRequestHash,
            amount: parseFloat(Math.round(order.PriceDetails.FinalAmount * 100) / 100).toFixed(2),
            firstname: order.BillingDetails.FirstName,
            email: order.BillingDetails.EmailAddr,
            phone: "9111111111",
            surl: 'localhost:8000/payment',
            furl: 'localhost:8000/payment',
            productinfo: order.PriceDetails.NewPlan.ProductFullName,
            // mode:'dropout'// non-mandatory for Customized Response Handling
        };

        var handler = {

            responseHandler: (BOLT) => {
                if (BOLT.response && BOLT.response.txnStatus!=="CANCEL") {
                    this.setState({proceedToStatusLoading: true})

                    processPayUMoney(token,BOLT.response,order.OrderRefNum)
                        .then(({error,response}) => {
                            this.props.setOrderPending(null);
                            if (error.status>=400) {
                                console.log('ERROR',error);
                            } else {
                                this.props.setOrderEvaluated(response);
                                navigate(getAccountPrefix(this.state.token)+'/order/payment/status');
                            }
                        })
                }
                // this.props.setOrderEvaluated(response);
              // your payment response Code goes here, BOLT is the response object
      
            },
            catchException: (BOLT) => {
              // the code you use to handle the integration errors goes here
      
            }
        }

        // var bolt = document.getElementById("bolt");
        this.setState({proceedOnlineLoading: false})
        window.bolt.launch( data , handler ); 
    }

    launchRazorPay = (order, token) => {
        var options = {
            "key": process.env.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
            "amount": order.PriceDetails.FinalAmount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "MProfit", //your business name
            "description": order.PriceDetails.NewPlan.ProductFullName,
            "image": "https://mprofit-newsletter.s3.amazonaws.com/mprofit-icon-razorpay.png",
            "order_id": order.RazorpayOrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": (response) => {
                this.setState({proceedOnlineLoading: true});

                processRazorpaySuccess(token,response,order.OrderRefNum)
                .then(({error,response}) => {
                    this.props.setOrderPending(null);
                    if (error.status>=400) {
                        console.log('ERROR',error);
                    } else {
                        this.props.setOrderEvaluated(response);
                        navigate(getAccountPrefix(this.state.token)+'/order/payment/status');
                    }
                })
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature)
            },
            "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                "name": order.BillingDetails.FirstName, //your customer's name
                "email": order.BillingDetails.EmailAddr, 
                "contact": order.BillingDetails.CellNum  //Provide the customer's phone number for better conversion rates 
            },
            "theme": {
                "color": "#022b54"
            }
        };

        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response){
                // alert(response.error.code);
                // alert(response.error.description);
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                // alert(response.error.metadata.payment_id);
        });

        this.setState({proceedOnlineLoading: false})

        rzp1.open();
    }
}

const mapStateToProps = (state) => {
    return {
        orderPlacementDetails: state.order.OrderPlacementDetails,
        orderConfirm: state.order.OrderConfirm,
        orderProcessingTokenData: state.order.OrderProcessingTokenData,
        orderPending: state.order.OrderPending,
        orderEvaluated: state.order.OrderEvaluated
    }
}
  
const mapDispatchToProps = dispatch => {
    return {
        setOrderPending: (orderPending) => dispatch({ type: `SET_ORDER_PENDING`, data: orderPending }),
        setOrderEvaluated: (orderEvaluated) => dispatch({ type: `SET_ORDER_EVALUATED`, data: orderEvaluated })
    }
}

const Payment = connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentComponent)  

export default Payment